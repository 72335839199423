//React
import React, { useState, useEffect, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';
import CreatableSelect from "react-select/creatable";
//Reux
import { useDispatch } from 'react-redux';

//Icons Material
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import '../styles.css'

//Component Material
import {
    Paper,
    Grid,
    TextField,
    Button,
    Container, CircularProgress,
    Typography,
    IconButton,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    CardActionArea,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slider, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Link from '@material-ui/core/Link'
import InputAdornment from '@material-ui/core/InputAdornment';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


//Local component 
import { useStyles } from '../calcStyle';
import clsx from 'clsx';
import { HelpValidate } from '../../../user/utils/helpValidate';
import { Copyright } from '../../../dashboard/componet/UI/CopyRight';

import {
    getParameters,
    getCommerceByName,
    generatePLans,
    saveChangeCalc,
    aprobSolicitud,
    preaprovedSolicitud,
    multiUpdate,
    getProductsByCommerceId,
    generatePLansNewComerce,
} from '../../domain/api/PrestapolisApi';

import { CODE_COMERCIO } from '../../../app/api-client/constants';


export const CalculadoraCreate = ({
    data,
    setopenMondal,
    reloadView,
    handledNext,
    setdataBusiness,
    dataBusiness,
    isCheck,
    setIscheck,
    rechValorPro,
    isCatalogo,
    catalogos,
    request,
    planSelect,
    planes,
    setisNewPro,
    isNewPro,
}) => {

    const classes = useStyles();
    const { register, setError, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const borderRadius = clsx(classes.padding, classes.borderRadius);
    const dispatch = useDispatch();

    let codeComer = localStorage.getItem(CODE_COMERCIO)

    const [name_prod, setName_prod] = useState("");

    const [listNameProd, setListNameProd] = useState("");
    const [abonoIsCatalogo, setAbonoIsCatalogo] = useState(0);

    const [valor_prod, setValor_prod] = useState("");
    const [abono_prod, setAbono_prod] = useState("");
    const [abono_init, setabono_init] = useState("")
    const [valor_init, setvalor_init] = useState("")
    const [optionStep, setOptionStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const [planSliderSelected, setPlanSliderSelected] = useState("4");
    const [bordeSelect,setBordeSelect]=useState(false)


    const [plazoPrestamo, setplazoPrestamo] = useState(0);
    const [maxEndeudamiento, setMaxEndeudamiento] = useState("");
    const [endeudamiento, setEndeudamiento] = useState(0)
    
    const [listParams, setListParams] = useState([]);
    const [comerceData, setComerceData] = useState({});
    const [cardConten, setCardConten] = useState(false)
    
    const [montoMin, setMontoMin] = useState("");
    const [montoMax, setMontoMax] = useState("");
    const [prestamoNeto, setPrestamoNeto] = useState("");
    const [esCuota, setEsCuotas] = useState([]);
    const [isMonto, setisMonto] = useState(false);
    const [isCardPlan, setisCardPlan] = useState(false);
    const [plans, setPlans] = useState([]);
    const [dataCard, setDataCard] = useState({});
    const [openDialog, setopenDialog] = useState(false);
    const [confirmSave, setconfirmSave] = useState(false);
    const [maskOptions, setmaskOptions] = useState([])
    const [disCard, setDisCard] = useState(0);
    const [indep, setIndep] = useState(0);
    const [showCalc, setshowCalc] = useState(false);
    const [tipoForm, setTipoForm] = useState("")
    const [tipoDeSolicitud, settipoDeSolicitud] = useState("")
    const [prodComerce, setprodComerce] = useState([]);
    const [catalogoState, setcatalogoState] = useState(false);
    const [multiSelectProductos, setMultiSelectProductos] = useState([])
    const [selectCatalogo, setCatalogo] = useState([]);
    let [options, setOptions] = useState(0);
    const [isProducto, setisProducto] = useState(false)
    const [button, setbutton] = useState(false)
    const [valorAbonoMinimo, setValorAbonoMinimo] = useState("");
    const [valorProducto, setvalorProducto] = useState("0");
    const formatCreateLabel = (inputValue) => `Agregar... ${inputValue}`;
    const [valorAbono, setValorAbono] = useState("");
    const [logValueAbono, setLogValueAbono] = useState(0)
    const [valorProductoAft, setvalorProductoAft] = useState("")
    const valorAbonoRef = createRef();
    const refvalue = createRef();

    const [estruCuotas, setEstrucuotas] = useState({});
    const [estados, setEstados] = useState({
        visible: false,
        showLoad: false,
        filter: "",
        title: "",
        message:
            "Debes calcular y seleccionar un plan de pago acorde a tus preferencias",
        type: "",
        catalogo: [0],
    });
    let meses = (dataCard.meses - 1)
    let mes = (abono_prod != 0 ?  meses : dataCard.meses)
    let servicioFianza = (dataCard.vsf/mes)
   

    const pushMultiProd = (catalogo) => {
        let multi = [];
        catalogo.map((item) => {
            multi.push({
                label: item.nombreProducto,
                value: item.excluidoIva === false ? item.valor : item.valorSinIva,
                id: item.id,
                descripcion: item.descripcion,
                descuento: item.descuento,
                excluidoIva: item.excluidoIva,
                estructuraCuotas: item.estructuraCuotas,
            });
        });

        setMultiSelectProductos(multi);

    }

    const [intemSelct, setintemSelct] = useState({
        plans: [],
        loanTerm: 0,
        porcentaje_vsf:0,
        costoPlataforma: 0,
        plazosolicitado: 0,
        interes: 0,
        selected: false,
    });

    let costo = listParams.filter((lParam) => lParam.key === "COSTO_PLATAFORMA");

    const c1 = createRef();
    const c2 = createRef();
    const c3 = createRef();

    const getListParms = () => {
        getParameters(dispatch, 0, 200, "")
            .then((response) => {
                if (response.code === "0000") {
                    setListParams(response.content);
                } else {
                  
                }
            })
            .catch((err) => {
                
            })
    }

    const submitFocus = () => {

        //c1.current.focus();
        //c2.current.focus();
        tipoForm !== "SIN_ABONO" && tipoDeSolicitud !== 5 && c3.current.focus();
    }

    const getDataComerceByCode = (codeComer) => {
        getCommerceByName(dispatch,localStorage.getItem(CODE_COMERCIO))
            .then((response) => {
                let data = response.respuesta;
                if (response.codigoRespuesta === "0000") {
                    setComerceData(data)
                    setMontoMax(data.montoMaximo);
                    setMontoMin(data.montoMinimo);
                    setPrestamoNeto(data.prestamo_neto)
                    setTipoForm(data.tipoFormulario);
                    setCardConten(data.porcentaje_vsf == 0 ? false : true)       
                } else {
                 
                }      
            })
            .catch((err) => {
               
            })
    }


    const calculePlan = (data) => {
   
     let catalogoSelect = selectCatalogo[0] === undefined || selectCatalogo[0] === null ?
     [] : selectCatalogo[0];
 

    let esCuotasProducto = catalogoSelect.estructuraCuotas;

    let isMultiPro = selectCatalogo.length > 0 ? true : false;

        let options = [];
        setshowCalc(true)
        let valor = data.valor_prod;
        if (typeof valor === "string") {
            valor = valor.includes(".") ? valor.split(".") : valor.split(",");
            valor = valor.join("");
            valor = parseInt(valor, 10);
        }
        let abono = data.Abono_prod;
        if (typeof abono === "string") {
            abono = abono.includes(".") ? abono.split(".") : abono.split(",");
            abono = abono.join("");
            abono = parseInt(abono, 10);
        }
        let resultado = (valor - abono)
        let esCuotas =
			isMultiPro !== true  ? comerceData.estructuraCuotas : 
			esCuotasProducto === null || 
			esCuotasProducto === undefined || 
			esCuotasProducto === 0 ?
			comerceData.estructuraCuotas : esCuotasProducto
            
        if (typeof esCuotas === "string") {
            esCuotas = esCuotas.includes('"')
                ? esCuotas.split("")
                : esCuotas.split("");
            esCuotas = esCuotas.join("");
            esCuotas = JSON.parse(esCuotas);
        }


        setEsCuotas(esCuotas);
        let request = {
            valor: valor,
            abono: tipoForm !== "SIN_ABONO" ? abono : 0,
            opciones: esCuotas,
            costoPlataforma: costo,
            seguro: 0, 
            porcentaje_vsf:comerceData.porcentaje_vsf, 
        };

        let newEndeudamiento = valor - abono;
        
        

        clearErrors();

        if (tipoForm === "ABONO_MINIMO_OBLIGATORIO" && valor <=  abono ) {
            
            setError("valor_prod", {
                type: "manual",
                message: `El valor del producto no puede ser menor al abono`,
            });
            setTimeout(() => {
                clearErrors();
            }, 10000);
            setshowCalc(false);

        }  else if(valor < comerceData.montoMinimo || valor > comerceData.montoMaximo ){
                setError("valor_prod", {
                    type: "manual",
                    message: comerceData.baas == false ? `El monto minimo es: $${formatPesos(montoMin)}, el monto maximo es: $${formatPesos(montoMax)}`:  `El préstamo neto máximo es de: $${formatPesos(prestamoNeto)}`,
                });
                setTimeout(() => {
                    clearErrors();
                }, 4000);
                setshowCalc(false);
            
        } else if(comerceData.baas == true ? resultado > comerceData.prestamo_neto : ""){
          
            setError("valor_prod", {
                type: "manual",
                message:  `El préstamo neto máximo es de: $${formatPesos(prestamoNeto)}`,
            });
            setTimeout(() => {
                clearErrors();
            }, 4000);
            setshowCalc(false);
        }
            
        else if (tipoForm === "ABONO_MINIMO_OBLIGATORIO" && parseInt(abono) < 20000) {
            
            setError("Abono_prod", {
                type: "manual",
                message: `El abono no pude ser menor al valor calculado`,
            });
            setTimeout(() => {
                clearErrors();
            }, 4000);
            setshowCalc(false);
        }else if (tipoForm === "ABONO_MINIMO_SUGERIDO" && parseInt(abono) < comerceData.valor_limite_inferior) {
            
            setError("Abono_prod", {
                type: "manual",
                message: `El abono no pude ser menor a: $${formatPesos(comerceData.valor_limite_inferior)}`,
            });
            setTimeout(() => {
                clearErrors();
            }, 4000);
            setshowCalc(false);
        }else {

            if(comerceData.apikey == process.env.REACT_APP_KEY_COMERCE){
                generatePLansNewComerce(dispatch, request, process.env.REACT_APP_ID_INTERES)
                .then((response) => {
                    
                    if (response.codigoRespuesta === "0000") {
                        let endeudamiento = valor - abono;
                    
                        setisCardPlan(true);
                        setshowCalc(false);
                        let plazo = 0;
                        response.respuesta.length > 0 &&
                            response.respuesta.forEach((option) => {
                                options.push({
                                    ...option,
                                    selected: "",
                                });
                            });
                        options.map((item, key) => {
                            let mes = parseInt(item.meses, 10);
                            if (mes === plazoPrestamo) {
                                plazo = key;
                            }
                        })

                       
                        setPlans(options);
                        setDataCard(options[plazo]);
                        setOptionStep(plazo);
                        completed.add(plazo)
                        let meses = parseInt(options[plazo].meses)
                        setPlanSliderSelected(meses)
                        setIndep(endeudamiento);
                        setDisCard(plazo)

                    } else {
                       
                    }
                    setshowCalc(false);
                })
                .catch((err) => {
                    setshowCalc(false);
                  
                });
                
            }else{

                generatePLans(dispatch, request)
                .then((response) => {
                    
                    if (response.codigoRespuesta === "0000") {
                        let endeudamiento = valor - abono;
                    
                        setisCardPlan(true);
                        setshowCalc(false);
                        let plazo = 0;
                        response.respuesta.length > 0 &&
                            response.respuesta.forEach((option) => {
                                options.push({
                                    ...option,
                                    selected: "",
                                });
                            });
                        options.map((item, key) => {
                            let mes = parseInt(item.meses, 10);
                            if (mes === plazoPrestamo) {
                                plazo = key;
                            }
                        })

                       
                        setPlans(options);
                        setDataCard(options[plazo]);
                        setOptionStep(plazo);
                        completed.add(plazo)
                        let meses = parseInt(options[plazo].meses)
                        setPlanSliderSelected(meses)
                        setIndep(endeudamiento);
                        setDisCard(plazo)

                    } else {
                       
                    }
                    setshowCalc(false);
                })
                .catch((err) => {
                    setshowCalc(false);
                  
                });
            }
            
        }

    };

    const formatPesos = (value) => {

        if(!value){
            return;
        }

        let number = ""
        number = new Intl.NumberFormat(["ban", "id"]).format(value)
        return number
    }


    const setCard = (data, key) => {
        setDataCard(data);
        setOptionStep(key);
    }


	const [calculaData, setcalculaData] = useState({
		name_prod: "",
		valor_prod: "",
		abono_prod: "",
	})

    const onSubmit = (data) => {

        if (refvalue.current) {
			refvalue.current.focus();
		}

        if(catalogoState){

           
            data.valor_prod = valorProducto.toString().replaceAll(".", "").replaceAll(",", "") != 0 ? valorProducto.toString().replaceAll(".", "").replaceAll(",", "") : valor_prod
            data.name_prod=listNameProd

            setAbonoIsCatalogo(data.abono_prod)

            setcalculaData({
                name_prod: data.name_prod,
                valor_prod: data.valor_prod,
                abono_prod: 0,
            });
    
            
          calculePlan(data)
         

        }else{
            setcalculaData({
                name_prod: data.name_prod,
                valor_prod: data.valor_prod,
                abono_prod: 0,
            });
    
            calculePlan(data)
        }

        

               
		
    }

    const generateDialog = () => {
        return (
            <Dialog
                // onClose={() => setopenDialog(false)}
                aria-labelledby="simple-dialog-title"
                open={openDialog}
            >
                <DialogTitle id="simple-dialog-title">¿Está seguro de guardar los datos?</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="Prymary" onClick={() => { acceptSave() }}>
                        Si
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => { noSave() }}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        )
    }
    //Abrir Modal antes de guardar
    const saveData = () => {


        let name = isCatalogo? listNameProd:name_prod;
        let valor = isCatalogo? valorProducto.toString().replaceAll(".", "").replaceAll(",", "") != 0 ?  valorProducto.toString().replaceAll(".", "").replaceAll(",", "") : valor_prod : valor_prod; 
       
        let abono = abono_prod == "" ? valorAbono.toString().replaceAll(".", "").replaceAll(",", "") : abono_prod;

        const data = {
            plazo: planSliderSelected,
            name_prod: name,
            valor_prod: valor,
            abono_prod: abono,
            costoPlataforma: dataCard.costo,
            idComerce: comerceData.id,
            porcentaje_vsf: comerceData.porcentaje_vsf,
            commission: comerceData.commission,
            dataCard: dataCard,
            plans: plans
        }
        setdataBusiness(data)
        handledNext();
    }

    //Dar si en el modal de aprovar
    const acceptSave = () => {
        setopenDialog(true);
        saveChange();
    }

    //Dar no en el modal de aprovar
    const noSave = () => {
        setopenDialog(false);
        setconfirmSave(false);
    }


    //Metodo para guardar datos calculados 
    const saveChange = () => {

        let valorProducto = valor_prod.replaceAll(".", "").replaceAll(",", "")
        valorProducto = parseInt(valorProducto);
        let valorAbono = abono_prod.replaceAll(".", "").replaceAll(",", "")
        valorAbono = parseInt(valorAbono)

        let request = {
            solicitud: {
                id: data.id
            },
            interes: {
                id: data.interes.id
            },
            monto: valorProducto,
            abono: valorAbono,
            plazo: planSliderSelected - 1
        }

        multiUpdate(dispatch, request)
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    setconfirmSave(false);
                    //changeStateRequest(data.id, 4);

                    store.addNotification({
                        title: "Exito!",
                        message: "¡Los datos han sido guardados con exito!",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeOut"],
                        animationOut: ["animate__animated", "animate__fadeIn"],
                        dismiss: {
                            duration: 3000,
                            // onScreen: true
                        }
                    });
                    setopenMondal(false);

                }
            })
            .catch((err) => {
                setconfirmSave(false);
                // changeStateRequest(data.id, 4);

                store.addNotification({
                    title: "Erro interno!",
                    message: "¡contacta al administrador!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeOut"],
                    animationOut: ["animate__animated", "animate__fadeIn"],
                    dismiss: {
                        duration: 3000,
                        // onScreen: true
                    }
                });
            })
    }

    //Metodo para cambiar estado de la solicitud get 
    const changeStateRequest = (id, state) => {
        aprobSolicitud(dispatch, id, state)
            .then((response) => {
                preaprovedRequest(id);
            })
            .catch((err) => {
                
            })
    }

    //Metodo para preaprovar solicitud post
    const preaprovedRequest = (id) => {
        preaprovedSolicitud(dispatch, id)
            .then((response) => {
              
                reloadView()
            })
            .catch((err) => {
                
            })
    }

    //Setear datos ya guardaso 

    const setDataComponent = () => {


        let name = dataBusiness.name_prod !== undefined && dataBusiness.name_prod !== null
            ? dataBusiness.name_prod
            : ""


        let valorProd = dataBusiness.valor_prod !== undefined && dataBusiness.valor_prod !== null
            ? dataBusiness.valor_prod
            : ""

        let abono_prod = dataBusiness.abono_prod !== undefined && dataBusiness.abono_prod !== null
            ? dataBusiness.abono_prod
            : ""

        let plazo = dataBusiness.plazo !== undefined && dataBusiness.plazo !== null
            ? dataBusiness.plazo
            : ""

        let dataCard = dataBusiness.dataCard !== undefined && dataBusiness.dataCard !== null
            ? dataBusiness.dataCard
            : {}

        let plans = dataBusiness.plans !== undefined && dataBusiness.plans !== null
            ? dataBusiness.plans
            : {}


        if (name !== "") {
            setisCardPlan(true)
            setPlans(plans)
        }

        setName_prod(name)
        setValor_prod(valorProd)
        setAbono_prod(abono_prod)
        setPlanSliderSelected(plazo)
        setplazoPrestamo(plazo)
        setDataCard(dataCard)


    }



    const formatNumber = (num) => {
        return new Intl.NumberFormat(["ban", "id"]).format(num);
    };
    const writeNumber = (text) => {
        if (!isNaN(text)) {
            return text;
        }
        return text.substring(0, text.length - 1);

    }

    //metodo para el calculo con catalogo
    const handleChange = (event) => {

        setCatalogo(event);
        const producto = event != "" ? true : false;
        setisProducto(producto);
        setbutton(false);
        const elemento = event;
        //let options = 0;
        let nombreP = "";
        prodComerce.forEach((option) => {
            nombreP = option.nombreProducto;

            elemento.forEach((elemnt) => {
                options = nombreP === elemnt.label ?
                    options + elemnt.value
                    : elemnt.__isNew__ === true ? options = "0" : options;
            })
        });
        
        let labelProducto = "";
        event.forEach((option) => {
            labelProducto += option.label+",";
            
        });
        setListNameProd(labelProducto)
        setvalorProducto(formatNumber(Math.floor(options)));

        let value = options;
        value = writeNumber(value);

        let valueAbono = ""
        let tipoForm = comerceData.tipoFormulario === undefined ? "NO_TIPO" : comerceData.tipoFormulario;

      
        if (tipoForm === "ABONO_MINIMO_OBLIGATORIO") {

            valueAbono = value * (comerceData.baseDivisorAbono / 100)
            valueAbono = Math.round(valueAbono / 100) * 100;
            valueAbono = Math.trunc(valueAbono);
            valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
            valueAbono = writeNumber(valueAbono);
            setValorAbonoMinimo(valueAbono);
            valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
            setValorAbono(valueAbono);
            setbutton(false);
            return

        } else if (tipoForm === "ABONO_MINIMO_SUGERIDO") {

            valueAbono = value * (comerceData.baseDivisorAbono / 100)
            valueAbono = Math.round(valueAbono / 100) * 100;
            valueAbono = Math.trunc(valueAbono);
            valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");
            valueAbono = writeNumber(valueAbono);
            valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
            setValorAbono(valueAbono);
            setLogValueAbono(valueAbono);
            setbutton(false);
            return

        } else if (tipoForm === "SIN_ABONO") {
            valueAbono = 0;
            setvalorProductoAft(value);
            setValorAbono(valueAbono);
            rechValorPro(options);
           
            setbutton(false);
            return
        } else {
            valueAbono = 0;
        }
    

        if (value === 0) {
            setValorAbono(0)
            setvalorProducto("")
            if (tipoForm === "ABONO_MINIMO_SUGERIDO" || tipoForm === "ABONO_MINIMO_OBLIGATORIO") {
                valorAbonoRef.current.value = "";
            }
            setValorAbonoMinimo(valueAbono);
            refvalue.current.focus();
        
        }

        valueAbono = valueAbono.toString().replaceAll(".", "").replaceAll(",", "");


        setvalorProductoAft(value);
        setValorAbono(parseInt(valueAbono, 10));
        setValorAbonoMinimo(valueAbono);

    };

    //METODO PARA FILTRAR CATALOGO
    const loadCatalogo = (filter) => {
        const id = comerceData.id;

       
        if(id == undefined) {
            return
        }

        let catalogo = [];
        setEstados({ showLoad: true });
        getProductsByCommerceId(dispatch, id, filter.toLowerCase())
            .then((response) => {
                if (response.codigoRespuesta === "0000") {
                    
                    response.respuesta.map((item) =>
                        catalogo.push({
                            ...item,
                            estructuraCuotas: JSON.parse(item.estructuraCuotas),
                        })
                    );
                    setprodComerce(catalogo);
                    setcatalogoState(true);
                } else {
                 
                }
            })
            .catch((err) => {
                setEstados({ visible: true });
               
            });
        setEstados({ showLoad: false });

    };

    
    const filter = (filter) => {
        setEstados({ filter: filter });
        if (estados.catalogo = [0]) {
            loadCatalogo("");
        }
    };

    useEffect(() => {

        setcatalogoState(isCatalogo);
        //getDataComerceByCode()

		if (refvalue.current) {
			refvalue.current.focus();
		}if (c1.current) {
			c1.current.focus()
		}

	  }, []);

    useEffect(() => {
        if (comerceData.tipoFormulario === "SIN_ABONO") {
            setIscheck(true)  
        }
    }, [prodComerce])

    useEffect(() => {
        
        setcatalogoState(isCatalogo);
        setisCardPlan(false)
        getListParms()
        clearErrors();
        getDataComerceByCode(codeComer)
        setDataComponent();

        if(isCatalogo) {
            pushMultiProd(catalogos);
            loadCatalogo("");
        }
	  }, [isCatalogo, codeComer, dataBusiness]);

      

    return (
        <>

            <div>{generateDialog()}</div>
            <div className={classes.centerv} >
                <Container maxWidth="xs" alignitems="center" >
                    <Paper className={borderRadius}>
                        <div className={classes.margin}>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="nowrap"

                            >
                                <Typography variant='subtitle1' color="textSecondary" component="p" align='center'>
                                    Inicia tu solicitud de crédito
                                </Typography>
                            </Grid>
                            <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
                                {catalogoState == false ? (
                                    <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '30px' }}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Nombre del producto *</InputLabel>
                                        <OutlinedInput
                                            id="name_prod"
                                            startAdornment={<InputAdornment position="start"><ShoppingCartIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                            labelWidth={165}
                                            fullWidth
                                            name="name_prod"
                                            autocomplete="off"
                                            value={name_prod}
                                            type="text"
                                            inputProps={{ className: "label__color", ref: c1 }}
                                            {...HelpValidate().validateText(register('name_prod', {
                                                required: "Por favor ingresa un valor del producto valido.",
                                                minLength: {
                                                    value: 2,
                                                    message: 'Ingresa un nombre valido'
                                                },
                                            }), setName_prod)}
                                            helperText={errors.name_prod?.message}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
                                        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
                                            <Grid item md={true} sm={true} xs={true}>
                                                <div style={{ fontWeight: "bold", fontSize: "11pt", width: "9rem" }}>
                                                    Seleccione Producto
                                                </div>
                                                <CreatableSelect
                                                    isMulti
                                                    classNamePrefix="select"
                                                    id="catalogo"
                                                    name="catalogo"
                                                    placeholder="Registra los productos"
                                                    options={multiSelectProductos}
                                                    defaultvalue={selectCatalogo}
                                                    value={selectCatalogo}
                                                    formatCreateLabel={formatCreateLabel}
                                                   
                                                    onChange={(event) => {
                                                        if (event === null || event.length <= 5) {
                                                            
                                                            handleChange(event)
                                                        } else {
                                                            alert("solo puedes agregar 5")
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )}

                                {catalogoState == false ? (
                                    <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-amount">Valor del producto *</InputLabel>
                                            <OutlinedInput
                                                id="valor_prod"
                                                startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                labelWidth={150}
                                                fullWidth
                                                autocomplete="off"
                                                name="valor_prod"
                                                value={valor_prod}
                                                inputProps={{ className: "label__color", ref: c2 }}
                                                type="tel"
                                                {...HelpValidate().calcAbonoCrete(register('valor_prod', {
                                                    required: "Por favor ingresa un valor del producto valido.",
                                                    minLength: {
                                                        value: 2,
                                                        message: 'Por favor ingresa un valor del producto valido'
                                                    },
                                                }), setValor_prod, setisCardPlan, setAbono_prod, comerceData)}
                                                helperText={errors.valor_prod?.message}
                                            />
                                        </FormControl>
                                    </Grid>

                                ) : (<Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}  >
                     
                                    {valorProducto === "0" && (  
                                        <Grid item md={true} sm={true} xs={true} style={{ zIndex: "0" }} >
                                              <InputLabel htmlFor="outlined-adornment-amount">Valor del producto *</InputLabel>
                                            <OutlinedInput
                                                id="valor_prod"
                                                startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                labelWidth={150}
                                                fullWidth
                                                autocomplete="off"
                                                name="valor_prod"
                                                value={valor_prod}
                                                defaultValue={valor_prod}
                                                inputProps={{ className: "label__color", ref: c2 }}
                                                type="tel"                                                                                         
                                                {...HelpValidate().calcAbonoCrete(register('valor_prod', {
                                                    required: "Por favor ingresa un valor del producto valido.",
                                                    minLength: {
                                                        value: 2,
                                                        message: 'Por favor ingresa un valor del producto valido'
                                                    },
                                                }), setValor_prod, setisCardPlan, setAbono_prod, comerceData)}
                                                helperText={errors.valor_prod?.message}
                                            />                
                                        </Grid>
                                    )
                                    }

                                    {valorProducto != "0" && (

                                        <Grid item md={true} sm={true} xs={true} style={{ zIndex: "0" }} className="input-person" id="input-select">
                                            <TextField
                                                placeholder="Valor de productos"
                                                label="Valor de productos"
                                                fullWidth
                                                startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                labelWidth={60}
                                                name="valor_prod"
                                                type="tel"
                                                id="input-select"
                                                variant="outlined"
                                                inputProps={{ className: "label__color", ref: c2 }}
                                                
                                                value={valorProducto || valor_prod}
                                                defaultValue={valorProducto || valor_prod}
                                                
                                                error={!!errors.valor_prod}
                                                helperText={errors.valor_prod?.message}
                                            />
                                        </Grid>
                                    )}

                                </Grid>

                                )}
                                <div className={classes.errorLabel}>{errors.valor_prod?.message}</div>

                                {comerceData.tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" &&

                                    <Grid container spacing={1} alignItems="flex-end" style={{ marginTop: '15px' }}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-amount">Abono *</InputLabel>
                                            <OutlinedInput
                                                id="abono_prod"
                                                startAdornment={<InputAdornment position="start"><AttachMoneyIcon color="primary" style={{ fontSize: 22 }} /></InputAdornment>}
                                                labelWidth={60}
                                                fullWidth
                                                autocomplete="off"
                                                name="abono_prod"
                                                value={abono_prod || valorAbono}
												defaultValue={abono_prod || valorAbono}
                                                type="tel"
                                                disabled={comerceData.tipoFormulario != "ABONO_MINIMO_SUGERIDO" ? false : isCheck}
                                                placeholder={comerceData.tipoDeSolicitud != "ABONO_MINIMO_SUGERIDO" ? "Valor Abono" : "Abono Sugerido"}
												label={comerceData.tipoDeSolicitud != "ABONO_MINIMO_SUGERIDO" ? "Valor Abono" : "Abono Sugerido"}
                                                inputProps={{ className: "label__color", ref: c3 }}
                                                {...HelpValidate().formatMoneyNumber(register('Abono_prod', {
                                                    required: "Por favor ingresa un valor abono valido.",
                                                    minLength: {
                                                        value: tipoForm === "ABONO_MINIMO_OBLIGATORIO" ? 4 : 0,
                                                        message: 'Ingresa un abono valido'
                                                    },
                                                   
                                                }), setAbono_prod, setisCardPlan, setValorAbono, comerceData, setError, clearErrors)}
                                                helperText={errors.Abono_prod?.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <div className={classes.errorLabel}>{errors.Abono_prod?.message}</div>

                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            disabled={showCalc}
                                            type="submit"
                                            autocomplete="off"
                                            variant="contained"
                                            color="primary"
                                            id='continuar'
                                            onMouseEnter={(e) => (submitFocus())}
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Calcular
                                        </Button>
                                    </Grid>
                                    {showCalc && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </form>

                            {isCardPlan &&
                                <div style={{ width: "100%" }}>
                                    <Typography id="discrete-slider-restrict" style={{ marginTop: "40px" }} gutterBottom>
                                        Plazo - meses
                                    </Typography>
                                    {plans.length > 0 && <Slider
                                        getAriaValueText={value => `${value}`}
                                        aria-labelledby="discrete-slider-custom"
                                        value={planSelect ? planSelect : planSliderSelected}
                                        step={null}
                                        marks={plans.map(({ meses }) => ({ value: meses, label: `${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" ? meses - 1 : meses}` }))}
                                        max={plans[plans.length - 1].meses}
                                        min={plans[0].meses}
                                        onChange={(_event, value) => {
                                            const key = plans.findIndex(({ meses }) => meses === value);
                                            const item = plans[key];
                                            setPlanSliderSelected(value);
                                            setCard(item, key);
                                        }}
                                    />}
                                    <p id="discrete-slider-custom" style={{ marginTop: "10pt", maxWidth: "14.06rem" }} gutterBottom>
                                        <strong>
                                            Esta seria tu cuota a pagar
                                        </strong>
                                    </p>

                                    {plans.length > 0 && plans.filter(({ meses }) => {
									if (planSelect) {
										return meses === planSelect
									}
									return meses === planSliderSelected
								})
									.map((item, key) => {
										return (<div
											className="select-plan"
											key={key}
											onClick={(e) => {
												setCard(item, key);
                                                
											}}
										>

                                    {!cardConten  ? (
                                        <Paper className={  bordeSelect  ? "form-field-selected form-field-border-select ":'form-field-selected'}
                                        onClick={()=>{setBordeSelect(true)}}
                                        >
                                        <Card variant="outlined"  style={{ borderRadius: "20px" }} >
                                            <CardActionArea >
                                                <CardContent>
                                                    <Typography variant="h6" component="h1" style={{ color: "#ff6700" }}>

                                                    </Typography>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                        <div style={{ marginTop: "5pt" }}>
                                                            <b className="text-planes" style={{ fontSize: '27px', color: "#4d4d4d" }}>
                                                                ${formatPesos(Math.floor((dataCard?.valor)))}
                                                            </b>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", color: "#FF6700 " }}>
                                                            <div>
                                                                <CalendarTodayIcon style={{ fontSize: '1.7rem', marginTop: "2px" }} />
                                                            </div>
                                                            <div style={{ marginTop: "10pt", marginLeft: "2px" }} >
                                                                <b className="text-planes" style={{ fontSize: '1rem' }}>
                                                                    {`${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" ? item.meses - 1 : item.meses} meses`}
                                                                </b>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <Typography className={classes.pos} style={{ color: "#676767" }}>
                                                        <Brightness1Icon style={{textAlign:'justify', fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        Interes: {dataCard?.interes === undefined ? "sin interes" : dataCard?.interes.interesmensual.toFixed(3)}%
                                                    </Typography>
                                
                                                    <Typography className={classes.pos} style={{ color: "#676767" }} >
                                                        <Brightness1Icon style={{ textAlign:'justify', fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        {`Costo administracion: $${formatPesos(dataCard?.costo === 0 ? '0' : dataCard?.costo / dataCard?.meses.toFixed(0) )} por cuota`}
                                                    </Typography>
                                            
                                                    <Typography align='center'>
                                                        <b className="text-planes" style={{ fontSize: '20px', color: "#4d4d4d" }}>
                                                            {`Total a pagar: $${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" ? formatPesos(Math.floor((dataCard?.pagoTotal))) : formatPesos(Math.floor((dataCard?.pagoTotal)))}`}
                                                        </b>
                                        </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Paper>

                                    ):(
                                        <Paper className={  bordeSelect  ? "form-field-selected form-field-border-select ":'form-field-selected'}
                                        onClick={()=>{setBordeSelect(true)}}
                                        >
                                        <Card variant="outlined"  style={{ borderRadius: "20px" }} >
                                            <CardActionArea >
                                                <CardContent>
                                                    <Typography variant="h6" component="h1" style={{ color: "#ff6700" }}>

                                                    </Typography>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                                                        <div style={{ marginTop: "5pt" }}>
                                                            <b className="text-planes" style={{ fontSize: '27px', color: "#4d4d4d" }}>
                                                            ${formatPesos(Math.floor((dataCard?.valor)))}
                                                            </b>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", color: "#FF6700 " }}>
                                                            <div>
                                                                <CalendarTodayIcon style={{ fontSize: '1.7rem', marginTop: "2px" }} />
                                                            </div>
                                                            <div style={{ marginTop: "10pt", marginLeft: "2px" }} >
                                                                <b className="text-planes" style={{ fontSize: '1rem' }}>
                                                                    {`${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" ? item.meses - 1 : item.meses} meses`}
                                                                </b>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <Typography className={classes.pos} style={{ color: "#676767" }}>
                                                        <Brightness1Icon style={{textAlign:'justify', fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        Interes: {dataCard?.interes === undefined ? "sin interes" : dataCard?.interes.interesmensual.toFixed(3)}%
                                                    </Typography>
                                                    <Typography className={classes.pos} style={{ color: "#676767" }}>
                                                        <Brightness1Icon style={{ textAlign:'justify', fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        {`Fianza FGA +IVA: $${formatPesos(Math.floor((servicioFianza)))} por cuota`}
                                                    </Typography>
                                                    <Typography className={classes.pos} style={{ color: "#676767" }} >
                                                        <Brightness1Icon style={{ textAlign:'justify', fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        {`Costo administracion: $${formatPesos(dataCard?.costo === 0 ? '0' : dataCard?.costo / dataCard?.meses.toFixed(0) )} por cuota`}
                                                    </Typography>
                                                    <Typography className={classes.pos} style={{ color: "#676767" }}>
                                                        <Brightness1Icon style={{textAlign:'justify',  fontSize: "12px", marginRight: "10px", color: "#2c8aaf" }} />
                                                        {`Seguro: $${formatPesos(dataCard.seguro === 0 ? "0" : dataCard?.seguro)}`}
                                                    </Typography>
                                                    <Typography align='center'>
                                                        <b className="text-planes" style={{ fontSize: '20px', color: "#4d4d4d" }}>
                                                            {`Total a pagar: $${tipoDeSolicitud !== 5 && tipoForm !== "SIN_ABONO" ? formatPesos(Math.floor((dataCard?.pagoTotal))) : formatPesos(Math.floor((dataCard?.pagoTotal)))}`}
                                                        </b>
                                        </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Paper>)}                
                                    
                                    </div>)
                                }
                                )}
                                </div>
                            }

                            {/* <p id="terminos-p">
                                * El beneficio del 0% interés aplica mientras se cumpla con
                                los pagos dentro de las fechas establecidas.&nbsp;
                                <Link href="https://www.prestapolis.com/consumidor-terminos-y-condiciones" target="_blank" style={{ color: "#0A699E" }} underline="hover">
                                    Ver términos y condiciones.
                                </Link>

                            </p> */}



                            {isCardPlan &&
                                <div className={classes.wrapper}>
                                    <Grid container justify="center" style={{ marginTop: '20px' }}>
                                        <Button
                                            disabled={!confirmSave && !dataCard?.valor}
                                            onClick={saveData}
                                            variant="contained"
                                            color="primary"
                                            id='continuar'
                                            style={{ textTransform: "none", width: "inherit" }}
                                        >
                                            Continuar
                                        </Button>
                                    </Grid>
                                    {confirmSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            }


                        </div>
                    </Paper>

                </Container >
            </div>
        </>
    );
}
