//React 
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';



//Redux
import { ID_USER, ID_SUCURSAL, ID_COMERCIO, USER_ROL, USER_AUTH0, URL_COMERCIO, NAME_COMERCIO, NAME_SUCURSAL, ID_SELLER, BAAS } from "../../app/api-client/constants";
import { useDispatch } from 'react-redux';

//Icons Material
import SendIcon from '@material-ui/icons/Send';

//Components Material
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    Button,
    Box,
    FormControl,
    InputLabel,
    FormControlLabel,
    Select,
    Input,
    MenuItem,
    Checkbox,
    TextField,
} from '@material-ui/core';

//Local Component
import Title from '../../app/orussystem/component/Title';
import MigaPan from '../../app/orussystem/component/MigaPan';
import { getVendedoresSucursal, getSucursalByIdCommerce, getAllCommerce, getUsersComerce } from '../domain/api/PrestapolisApi'
import { Autocomplete } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
    box: {
        paddingTop: 10,
        display: "flex",

    },
    spreadBox: {
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "50px"
    },
    spreadBoxTitle: {
        justifyContent: "center",
        alignItems: "center",
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            width: 250,
            position: "absolute !important",
            top: "800px"
        },
    },
};


export const Administrator = () => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [comercios, setComercios] = useState("")

    const [arrayUsersCommerce, setarrayUsersCommerce] = useState([])
    const [arrayComercios, setarrayComercios] = useState([])
    const [urlComerce, seturlComerce] = useState("");
    const [usuarios, setUsuarios] = useState("");
    const [selectSucursal, setSelectSucursal] = useState({});
    let nameComercio = localStorage.getItem(NAME_COMERCIO)
    let baas = localStorage.getItem(BAAS)
    let namecomerce = baas != "true" ? 'Home' : nameComercio
  


    const GetCommerce = (page, size, filter) => {
        getAllCommerce(dispatch, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {
                    const data = response.content;
                    setarrayComercios(data);
                } else {

                }
            })
            .catch((error) => {

                if (error.status === 401) {
                }
            })
    }

    const getUserCommerceById = (id, page, size, filter) => {
        getUsersComerce(dispatch, id, page, size, filter)
            .then((response) => {
                if (response.code === "0000") {

                    let data = response.content;
                    setarrayUsersCommerce(data)

                } else {

                }
            }).catch((error) => {

                if (error.status === 401) {
                }
            })
    }




    useEffect(() => {
        GetCommerce(0, 500, "")
    }, [])



    const handleChangeComercios = (event) => {
        let urlComerce = ""
        let letUsers = 100

        arrayComercios.map((item) => {
            if (item.id === event.target.value) {
                urlComerce = item.url
            }
        })

        seturlComerce(urlComerce)

        setComercios(event.target.value)
        getUserCommerceById(event.target.value, 0, letUsers, "")
        setUsuarios("")

    };

    const handledChangeUsuarios = (event,) => {
        setUsuarios(event.target.value)
    }


    const handledNameRol = (id) => {
        let nameRol
        switch (id) {
            case 1:
                nameRol = "Admin Comercio"
                break;

            case 2:
                nameRol = "Admin Sucursal"
                break;

            case 3:
                nameRol = "Vendedor"
                break;

            default:
                nameRol = "Sin rol asignado"
                break;
        }

        return nameRol
    }


    const HandeldTypeRol = () => {



        localStorage.removeItem(ID_COMERCIO);
        localStorage.removeItem(ID_SUCURSAL);
        localStorage.removeItem(ID_USER);
        localStorage.removeItem(URL_COMERCIO);
        localStorage.removeItem(USER_ROL);
        localStorage.removeItem(NAME_COMERCIO);
        localStorage.removeItem(NAME_SUCURSAL);
        localStorage.removeItem(ID_SELLER);

        let nameComerce = ""
        arrayComercios.map((item) => {
            if (item.id === comercios) {
                nameComerce = item.nombre
            }
        })

        let nameSucursal = "";
        let sucursal_id = "";


        if (usuarios != "" && comercios != "") {

            localStorage.setItem(ID_COMERCIO, comercios)
            localStorage.setItem(ID_SUCURSAL, usuarios.sucursales)
            localStorage.setItem(ID_USER, usuarios.id_usuario)
            localStorage.setItem(URL_COMERCIO, urlComerce)
            localStorage.setItem(USER_ROL, usuarios.rol.rol)
            localStorage.setItem(NAME_COMERCIO, nameComerce)


            store.addNotification({
                title: "¡Exito!",
                message: "Espere aplicando cambios",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1500,
                    // onScreen: true
                }
            });
            setTimeout(() => {
                history.push(`/`);
                window.location.reload()
            }, 1500);

        } else if (usuarios === "" && comercios != "") {

            store.addNotification({
                title: "¡Alerta!",
                message: "Señor usuario recuerde que para poder aplicar cambios debe seleccionar un usuario",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3800,
                    // onScreen: true
                }
            });
        } else {


            store.addNotification({
                title: "¡Error!",
                message: "Ningun comercio seleccionado",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    // onScreen: true
                }
            });
        }
    }


    return (
        <>
            <Grid item xs={12} >
                <MigaPan namePage="Admin" to="/" >
                {namecomerce}
                </MigaPan>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBoxTitle} ${classes.box}`}
                        >
                            <Title >Informacion</Title>
                        </Box>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >


                            <div style={{ textAlign: "justify", fontSize: "17px" }}> Señor administrador para hacer correcto uso de este modulo, primero seleccione los filtros que desea aplicar, y luego precione en el boton de aplicar cambios.</div>


                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBoxTitle} ${classes.box}`}
                        >
                            <Title >Comercios</Title>

                        </Box>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >

                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-mutiple-checkbox-label">Selecciona un Comercios</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    // multiple
                                    value={comercios}
                                    onChange={handleChangeComercios}
                                    input={<Input />}
                                    // renderValue={ }
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="">
                                        Reset
                                    </MenuItem>

                                    {arrayComercios.map((item, key) => (
                                        <MenuItem key={key} value={item.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.id === comercios}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label={item.nombre}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBoxTitle} ${classes.box}`}
                        >
                            <Title >Usuarios</Title>
                        </Box>
                        <Box
                            m={2}
                            component="span"
                            className={`${classes.spreadBox} ${classes.box}`}
                        >
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Grid item md={true} sm={true} xs={true} >
                                    <Autocomplete
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        input={<Input />}
                                        MenuProps={MenuProps}
                                        options={arrayUsersCommerce}
                                        getOptionLabel={
                                            (option) => option.nombreCompleto == undefined ? "" : option.nombreCompleto + " - " + option.cargo}
                                        value={usuarios}
                                        onChange={(event, newValue) => {
                                            setUsuarios(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Selecciona un usuario" />}
                                    >
                                    </Autocomplete>
                                </Grid>

                                {/* <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    value={usuarios}
                                    onChange={handledChangeUsuarios}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="">
                                        Reset
                                    </MenuItem>
                                    {arrayUsersCommerce.map((item, key) => (
                                        <MenuItem key={key} value={item}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.id === usuarios.id}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label={`${item.nombreCompleto} - ${handledNameRol(item.rol.id)}`}
                                            />
                                        </MenuItem>
                                    ))}
                                            </Select>*/}


                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                style={{ paddingTop: "50px", paddingBottom: "50px" }}

            >
                <Button

                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={(e) => {
                        HandeldTypeRol()

                    }}
                    startIcon={<SendIcon />}

                >
                    Aplicar Cambios
                </Button>
            </Grid>

        </>
    )
}
